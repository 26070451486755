<template>
  <v-dialog
    :value="true"
    max-width="500"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>{{ $t('sendInvoice#') }}{{ invoice.invoiceNumber }}</v-card-title>
      <v-card-text>
        <v-text-field
          small
          outlined
          :label="$t('email')"
          v-model="email"
        />
        <v-textarea
          hide-details
          small
          outlined
          :label="$t('message')"
          v-model="message"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="processing"
          :disabled="processing"
          block
          large
          color="primary"
          @click="sendInvoice"
        >
          {{ $t('send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'InvoiceSendDialog',
    props: {
      invoice:{
        type: Object, 
        default: () => {}
      }
    },
    data() {
        return {
            processing: false,
            email: null,
            message: null
        }
    },
    methods: {
        sendInvoice() {
            this.processing = true
            this.$store.dispatch('sendInvoice', {
                email: this.email,
                message: this.message,
                invoice: this.invoice
            }).then(success => {
                if(success === true) {
                    this.close()
                }
            })
            .finally(() => {
                this.processing = false
            })
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {
        this.email = this.invoice.customer.email
    }
}
</script>