<template>
  <v-dialog
    :value="true"
    max-width="500px"
  >
    <v-card>
      <v-card-title>{{ $t('newInvoice') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              :loading="fetchingCustomers"
              dense
              :label="$t('customer')"
              outlined
              :items="customerOptions"
              item-value="uuid"
              item-text="name"
              v-model="customerToInvoice"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              dense
              :label="$t('channel')"
              outlined
              :items="channelOptions"
              item-value="uuid"
              item-text="label"
              v-model="channelToInvoice"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <LineItemsManager v-model="lineItems" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          large
          block
          color="primary"
          :disabled="!canSendInvoice"
          @click="sendInvoice"
        >
          {{ $t('sendInvoice') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import LineItemsManager from '../lineItem/LineItemsManager.vue'

export default {
    name: 'CreateInvoiceDialog',
    components: {
        LineItemsManager
    },
    data() {
        return {
            fetchingCustomers: false,
            customerToInvoice: null,
            channelToInvoice: null,
            lineItems: [],
        }
    },
    computed: {
        canSendInvoice() {

            if(this.customerToInvoice === null) {
                return false
            }

            if(this.channelToInvoice === null) {
                return false
            }

            return true
        },
        channelOptions() {
            return this.$store.state.channels.map(channel => {
                return {
                    uuid: channel.uuid,
                    label: channel.label
                }
            })
        },
        customerOptions() {

            if(this.$store.state.customers === null) {
                return []
            }

            return this.$store.state.customers.map(customer => {
                return {
                    uuid: customer.uuid,
                    name: customer.address.name_line
                }
            })
        }
    },
    methods: {
        sendInvoice() {

        },
        fetchCustomers() {
            this.fetchingCustomers = true
            this.$store.dispatch('getCustomers', {}).finally(() => {
                this.fetchingCustomers = false
            })
        }
    },
    mounted() {
        this.fetchCustomers()
    }
}
</script>
