<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('totalAmount') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ formattedPrice }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
    name: 'InvoiceTotals',
    props: {
        invoice: {
            type: Object, 
            default: () => {}
        }
    },
  computed: {
      formattedPrice() {
        if(typeof this.invoice !== 'undefined' && this.invoice !== null) {
          return this.$store.getters.formatAmountByCurrency(this.invoice.orderTotal)
        }
        return ''
      }
  }
}
</script>