<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ lineItem.label }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'LineItem',
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    }
}
</script>