<template>
  <v-card v-if="invoice !== null && invoice !== undefined">
    <InvoiceSendDialog
      v-if="invoiceToSend !== null"
      :invoice="invoiceToSend"
      @close="invoiceToSend = null"
    />
    <ConfirmDialog
      v-model="showConfirmCreditInvoice"
      v-if="showConfirmCreditInvoice"
      :title="$t('confirmAction')"
      :sub-title="$t('pleaseConfirmCreditInvoice')"
      @closed="closeConfirmCreditInvoiceDialog"
      @confirmed="confirmCreditInvoice"
    />    
    <v-card-title>{{ $t('Invoice#') }}{{ invoice.invoiceNumber }}</v-card-title>
    <v-card-subtitle>{{ customerName }} - {{ customerSsn }} - {{ $moment(invoice.created).format('DD/MM/YYYY HH:mm') }}</v-card-subtitle>
    <v-card-text v-if="invoice.comment">
      {{ invoice.comment }}
    </v-card-text>
    <v-card-text v-if="invoice.reference">
      {{ invoice.reference }}
    </v-card-text>
    <InvoiceLineItems
      class="px-2"
      :line-items="invoice.lineItems"
    />
    <InvoiceTotals
      class="px-2"
      :invoice="invoice"
    />
    <v-card-text
      class="pb-0"
      v-if="errors.length > 0"
    >
      <template v-for="(error, i) in errors">
        <v-alert
          :key="i"
          color="red"
          dark
        >
          {{ error }}
        </v-alert>
      </template>
    </v-card-text>
    <v-card-text
      class="pb-0"
      v-if="successes.length > 0"
    >
      <template v-for="(success, i) in successes">
        <v-alert
          :key="i"
          color="green"
          dark
        >
          {{ success }}
        </v-alert>
      </template>
    </v-card-text>
    <v-card-text
      class="px-2"
      v-if="invoice.externalDeliveryResults !== null && invoice.externalDeliveryResults !== undefined && invoice.externalDeliveryResults.length > 0"
    >
      <v-subheader>{{ $t('externalDeliveryResults') }}</v-subheader>
      <v-list class="dense pt-0">
        <template v-for="(result, i) in invoice.externalDeliveryResults">
          <v-list-item :key="i">
            <v-list-item-content>
              <v-list-item-title>{{ result.namespace }}</v-list-item-title>
              <v-list-item-subtitle style="white-space: pre-wrap;">
                {{ result.created | moment("dddd, MMMM Do YYYY, HH:mm:ss") }} - {{ result.message }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                v-if="result.success"
                color="green"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="red"
              >
                mdi-alert
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="flex"
        flex
        color="primary"
        link
        @click="creditInvoice"
        :disabled="!canCreditInvoice"
        :loading="creditingInvoice"
      >
        {{ $t('creditInvoice') }}
      </v-btn>
      <v-btn
        class="flex"
        flex
        color="primary"
        link
        target="_blank"
        :href="'https://service-document.salescloud.is/?uuid=' + invoice.uuid + '&type=invoice'"
      >
        {{ $t('downloadInvoice') }}
      </v-btn>
      <v-btn
        class="flex"
        flex
        color="primary"
        @click="invoiceToSend = invoice"
      >
        {{ $t('sendInvoice') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import InvoiceLineItems from './InvoiceLineItems.vue'
import InvoiceSendDialog from '../InvoiceSendDialog.vue'
import InvoiceTotals from './InvoiceTotals.vue'
import ConfirmDialog from '../common/ConfirmDialog.vue'

export default {
    name: 'InvoiceProfile',
    props: {
        invoice: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        InvoiceLineItems,
        InvoiceSendDialog,
        InvoiceTotals,
        ConfirmDialog
    },
    data() {
        return {
            creditingInvoice: false,
            invoiceToSend: null,
            errors: [],
            successes: [],
            showConfirmCreditInvoice: false,
        }
    },
    computed: {
      canCreditInvoice() {

        if(this.creditingInvoice) {
          return false
        }

        if(this.invoice.isCredited) {
          return false
        }

        return true
      },
			customerName() {
				if(typeof this.invoice === 'undefined' || this.invoice === null) {
					return ''
				}
				if(typeof this.invoice.customer === 'undefined' || this.invoice.customer === null) {
					return ''
				}
				if(typeof this.invoice.customer.address === 'undefined' || this.invoice.customer.address === null) {
					return ''
				}
				if(typeof this.invoice.customer.address.name_line === 'undefined' || this.invoice.customer.address.name_line === null) {
					return ''
				}
				return this.invoice.customer.address.name_line
			},
			customerSsn() {
				if(typeof this.invoice === 'undefined' || this.invoice === null) {
					return ''
				}
				if(typeof this.invoice.customer === 'undefined' || this.invoice.customer === null) {
					return ''
				}
				if(typeof this.invoice.customer.ssn === 'undefined' || this.invoice.customer.ssn === null) {
					return ''
				}
				return this.invoice.customer.ssn
			}
    },
    methods: {
      creditInvoice() {
        this.showConfirmCreditInvoice = true
      },
      closeConfirmCreditInvoiceDialog() {
        this.showConfirmCreditInvoice = false
      },
      confirmCreditInvoice() {
        this.errors = []
        this.creditingInvoice = true
        this.$store.dispatch('creditInvoice', this.invoice).then(creditedInvoice => {

          if(creditedInvoice === null) {
            this.errors.push(this.$t('unknownErrorOccuredNoCreditInvoiceWasCreated'))
          }
          else {
            this.successes.push(this.$t('creditedInvoiceHasBeenCreated'))
            this.$store.dispatch('getInvoices')
          }

        }).finally(() => {
          this.creditingInvoice = false
          this.showConfirmCreditInvoice = false
        })
      }
    }
}
</script>
