<template>
  <v-list-item
    three-line
    @click="viewInvoice"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ invoice.invoiceNumber }}
      </v-list-item-title>
      <v-list-item-subtitle>{{ customerName }}</v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ $moment(invoice.created).format('DD/MM/YYYY HH:mm') }} <span v-if="invoice.channel !== null">- {{ invoice.channel.label }} </span>- {{ invoice.orderId }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="false">
      <v-btn
        link
        target="_blank"
        :href="'https://service-document.salescloud.is/?uuid=' + invoice.uuid + '&type=invoice'"
        icon
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-list-item-action>
    <!---->
    <v-list-item-action v-if="!hasPendingPayment && !isConsideredPaidInFull && false">
      <v-tooltip
        v-model="showTooltip1"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click.prevent=""
            link
            icon
            v-on="on"
          >
            <v-icon>mdi-bank-off</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('noBankingClaimHasBeenCreated') }}</span>
      </v-tooltip>
    </v-list-item-action>

    <v-list-item-action v-if="hasPendingPayment && !isConsideredPaidInFull && false">
      <v-tooltip
        v-model="showTooltip2"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click.prevent=""
            link
            icon
            color="primary"
            v-on="on"
          >
            <v-icon>mdi-bank</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('bankingClaimHasBeenCreatedAndNotBeenPaid') }}</span>
      </v-tooltip>
    </v-list-item-action>

    <v-list-item-action v-if="isConsideredPaidInFull && !hasPendingPayment && false">
      <v-tooltip
        v-model="showTooltip3"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click.prevent=""
            link
            icon
            color="green"
            v-on="on"
          >
            <v-icon>mdi-bank-check</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('bankingClaimWasCreatedAndPaid') }}</span>
      </v-tooltip>
    </v-list-item-action>

    <v-list-item-action v-if="hasSuccessFullExternalDelivery && false">
      <v-tooltip
        v-model="showTooltip4"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click.prevent=""
            link
            icon
            color="green"
            v-on="on"
          >
            <v-icon>mdi-text-box-check</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('deliveredToInExchangeOrExternalAccountingSystem') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="!hasSuccessFullExternalDelivery && false">
      <v-tooltip
        v-model="showTooltip5"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click.prevent=""
            link
            icon
            v-on="on"
          >
            <v-icon>mdi-text-box-remove</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('hasNotBeenDeliveredToInExchangeOrExternalAccountingSystem') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <!---->
    <v-list-item-action v-if="false">
      <v-btn
        @click.prevent=""
        link
        icon
        disabled
      >
        <v-icon>mdi-email</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-action v-if="false">
      <v-btn
        @click.prevent=""
        @click="sendInvoice"
        link
        icon
      >
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
    name: 'InvoiceListItem',
    props: {
      invoice:{
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        showTooltip1: false,
        showTooltip2: false,
        showTooltip3: false,
        showTooltip4: false,
        showTooltip5: false
      }
    },
    methods: {
        sendInvoice() {
            this.$emit('sendInvoice', this.invoice)
        },
        viewInvoice() {
            this.$emit('viewInvoice', this.invoice)
        }
    },
    computed: {
      hasPendingPayment() {
        return this.invoice.payments.find(payment => payment.status === 'pending') !== undefined
      },
      isConsideredPaidInFull() {
        return this.invoice.balance.amount === 0
      },
      hasSuccessFullExternalDelivery() {

        if(this.invoice.externalDeliveryResults === null || this.invoice.externalDeliveryResults === undefined) {
          return false
        }

        return this.invoice.externalDeliveryResults.find(result => result.success)
      },
			customerName() {
				if(typeof this.invoice === 'undefined' || this.invoice === null) {
					return ''
				}
				if(typeof this.invoice.customer === 'undefined' || this.invoice.customer === null) {
					return ''
				}
				if(typeof this.invoice.customer.address === 'undefined' || this.invoice.customer.address === null) {
					return ''
				}
				if(typeof this.invoice.customer.address.name_line === 'undefined' || this.invoice.customer.address.name_line === null) {
					return ''
				}
				return this.invoice.customer.address.name_line
			}
    }
}
</script>
