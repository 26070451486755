<template>
  <v-list>
    <template v-for="lineItem in lineItems">
      <InvoiceLineItem
        :key="lineItem.uuid"
        :line-item="lineItem"
      />
    </template>
  </v-list>
</template>

<script>

import InvoiceLineItem from './InvoiceLineItem'

export default {
    name: 'InvoiceLineItems',
    props: {
        lineItems: {
            type: Array, 
            default: () => []
        }
    },
    components: {
        InvoiceLineItem
    }
}
</script>