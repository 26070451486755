<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ lineItem.label }} ({{ lineItem.quantity }} x {{ lineItem.unitPrice | price }}) </v-list-item-title>
      <v-list-item-subtitle>{{ $moment(lineItem.created).format('DD/MM/YYYY HH:mm') }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content style="text-align: right;">
      <v-list-item-title>{{ formattedPrice }} </v-list-item-title>
      <v-list-item-subtitle>{{ lineItem.discountPercent }}%</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'InvoiceListItem',
    props: {
        lineItem: {
            type: Object, 
            default: () => {}
        }
    },
  computed: {
      formattedPrice() {
        if(typeof this.lineItem !== 'undefined' && this.lineItem !== null) {
          return this.$store.getters.formatAmountByCurrency(this.lineItem.totalPrice)
        }
        return ''
      }
  }
}
</script>