<template>
  <div>
    <div>
      <InvoiceSendDialog
        v-if="invoiceToSend !== null"
        :invoice="invoiceToSend"
        @close="invoiceToSend = null"
      />
      <ActionList
        :filters="filters"
        :list-items="invoices"
        :actions="actions"
        :loading="loading"
        db-table="invoices"
        :load-items-callback="loadItemsCallback"
        :fixed-filters="fixedFilters"
        :sort-list-items-callback="sortInvoices"
        :requires-remote-filtering="true"
        :empty-text="$t('noInvoicesFound')"
      >
        <template v-slot:item="{ item, index }">
          <InvoiceListItem
            @viewInvoice="viewInvoice(item)"
            @sendInvoice="sendInvoice"
            :key="item.uuid"
            :invoice="item"
          />
          <v-divider
            :key="index"
            inset
          />
        </template>
      </ActionList>
      <div v-intersect.quiet="loadMore" />
    </div>
  </div>
</template>

<script>

  import InvoiceListItem from "./InvoiceListItem"
  import InvoiceSendDialog from "./InvoiceSendDialog.vue"
  import ActionList from "./common/list/ActionList"

  export default {
    name: 'ItemList',
    props: {
      invoices: {
        type: Array,
        default: () => []
      },
      actions: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: () => false
      },
      loadItemsCallback: {
        type: Function,
        default: () => null
      },
      fixedFilters: {
        type: Array,
        default: () => []
      },
    },
    components: {
      ActionList,
      InvoiceListItem,
      InvoiceSendDialog
    },
    data: () => ({
      length: 100,
      step: 100,
      isLoadingMore: false,
      invoiceToSend: null,
      invoiceToView: null,
      invoiceModal: false
    }),
    computed: {
      filters() { return [
				{
					key: 'created',
					label: this.$t('dateCreated'),
					type: 'datetime',
					value: [
						0,
						0
					],
					operator: 'between'
				},
        {
          key: 'customer.address.name_line',
          label: this.$t('name'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'customer.phone',
          label: this.$t('phone'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'customer.ssn',
          label: this.$t('SSN'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'customer.email',
          label: this.$t('email'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'invoiceNumber',
          label: this.$t('invoiceNumber'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'uuid',
          label: this.$t('universalUniqueIdentifier'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'orderId',
          label: this.$t('orderId'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'orderTotal.amount',
          label: this.$t('totalAmount'),
          type: 'text',
          value: null,
          operator: 'equals'
        },
        {
          key: 'channel.uuid',
          label: this.$t('channel'),
          type: 'select',
          value: null,
          items: this.channelOptions,
          operator: 'contains'
        },
        {
          key: 'location.uuid',
          label: this.$t('location'),
          type: 'select',
          value: null,
          items: this.locationOptions,
          operator: 'contains'
        },
        {
          key: 'isCredited',
          label: this.$t('isCredited'),
          type: 'text',
          value: null,
          operator: 'equals'
        },
        {
          key: 'externalDeliveryResults.success',
          label: this.$t('deliveredToExternalAccountingSystem'),
          type: 'boolean',
          value: null,
          operator: 'contains'
        }
      ]},
      channelOptions() {
        return this.$store.state.channels.map(channel => {
          return {
            value: channel.uuid,
            text: channel.label
          }
        })
      },
      locationOptions() {
        return this.$store.state.locations.map(location => {
          return {
            value: location.uuid,
            text: location.label
          }
        })
      }
    },
    methods: {
      sortInvoices(i1,i2) {
        return i2.created - i1.created
      },
      exportInvoices() {

        if(this.filteredInvoices !== null && this.filteredInvoices !== undefined) {
          this.$store.commit('updateExportObjects', this.filteredInvoices)
        }
        else {
          this.$store.commit('updateExportObjects', this.invoices)
        }

      },
      viewInvoice(invoice) {
        this.$emit('selectInvoice', invoice)
      },
      sendInvoice(invoice) {
        this.invoiceToSend = invoice
      },
      loadMore() {
        this.length = this.length + this.step
      }
    },
    mounted() {
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportInvoices
      })
    }
  }
</script>
