<template>
  <div>
    <CreateInvoiceDialog v-if="showCreateInvoiceDialog === true" />
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedInvoice"
    >
      <template v-slot:list="{ item }">
        <InvoiceList
          v-if="item.key === 'all'"
          @selectInvoice="selectInvoice"
          :actions="actions"
          :load-items-callback="getInvoices"
        />
        <InvoiceList
          v-if="item.key === 'credited_invoices'"
          :fixed-filters="creditedInvoicesFilters"
          @selectInvoice="selectInvoice"
          :actions="actions"
          :load-items-callback="getInvoices"
        />
        <InvoiceList
          v-if="item.key === 'credit_invoices'"
          :fixed-filters="creditInvoicesFilters"
          @selectInvoice="selectInvoice"
          :actions="actions"
          :load-items-callback="getInvoices"
        />
        <InvoiceList
          v-if="item.key === 'external_accounting'"
          @selectInvoice="selectInvoice"
          :actions="actions"
          :load-items-callback="getInvoices"
        />
        <InvoiceList
          v-if="item.key === 'not_external_accounting'"
          @selectInvoice="selectInvoice"
          :actions="actions"
          :load-items-callback="getInvoices"
        />
      </template>
      <template slot="profile">
        <InvoiceProfile :invoice="selectedInvoice" />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
  import InvoiceList from '@/components/InvoiceList'
  import CreateInvoiceDialog from '@/components/invoice/CreateInvoiceDialog'
  import InvoiceProfile from '@/components/invoice/InvoiceProfile'
	import ExtendedView from "@/components/common/ExtendedView"

  export default {
    name: 'Invoices',
    components: {
      InvoiceList,
      CreateInvoiceDialog,
      InvoiceProfile,
			ExtendedView
    },
    data: () => ({
      showCreateInvoiceDialog: false,
      selectedInvoice: null,
      loading: false,
      creditedInvoicesFilters: [
        {
          key: 'isCredited',
          value: true,
          operator: 'equals',
          type: 'boolean'
        },
      ],
      creditInvoicesFilters: [
        {
          key: 'orderTotal.amount',
          value: 0,
          operator: 'lessThan',
          type: 'number'
        }
      ]
      /* TODO create action list functionality that handles arrays and callback functions
      ,
      sentToExternalAccountingFilters:
      [
        {
          key: '',
          value: '',
          operator: '',
          type: ''
        }
      ],
      notSentToExternalAccountingFilters:
      [
        {
          key: '',
          value: '',
          operator: '',
          type: ''
        }
      ]
      */
    }),
    methods: {
      getInvoices({skip, conditions, limit}) {
        return this.$store.dispatch('getInvoices', {
          skip: skip,
					conditions: conditions,
					limit: limit
        })
      },
      selectInvoice(invoice) {
        this.selectedInvoice = invoice
      },
      createInvoice() {
        this.showCreateInvoiceDialog = true
      }
    },
    watch: {
      invoices() {
        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    },
    computed: {
      actions() {
        return this.$store.getters.bulkOperationsByRoute('sales/invoices')
      },
      selectedAppBarTab() {
			  return this.$store.state.selectedAppBarTab
		  },
      invoices() {

        if(this.$store.state.invoices === null || this.$store.state.invoices === undefined) {
          return null
        }

        return this.$store.state.invoices.filter(invoice => invoice !== null && invoice.invoiceNumber !== null && invoice.customer !== null && invoice.customer.address !== null && invoice.customer.address.name_line !== null)
      },
      creditedInvoices() {
        if(this.$store.state.invoices === null || this.$store.state.invoices === undefined) {
          return null
        }

        return this.$store.state.invoices.filter(invoice => invoice !== null && invoice.invoiceNumber !== null && invoice.customer !== null && invoice.customer.address !== null && invoice.customer.address.name_line !== null && invoice.isCredited)
      },
      creditInvoices() {

        if(this.$store.state.invoices === null || this.$store.state.invoices === undefined) {
          return null
        }

        return this.$store.state.invoices.filter(invoice => invoice !== null && invoice.invoiceNumber !== null && invoice.customer !== null && invoice.customer.address !== null && invoice.customer.address.name_line !== null && invoice.orderTotal.amount < 0)
      },
      /* Old tab functionality
      sentToExternalAccounting() {
        if(this.$store.state.invoices === null || this.$store.state.invoices === undefined) {
          return null
        }

        return this.$store.state.invoices.filter(order => {
          let result = false

          if (order === null) {
            return false
          }

          if (order.externalDeliveryResults === null || order.externalDeliveryResults === undefined) {
            return false
          }

          if (order.externalDeliveryResults.length < 1) {
            return false
          }

          for (let index in order.externalDeliveryResults) {
            // Find one single instance of this namespace where the result is true, if it's not true, then just return true if this for loop finishes, return false
            result = order.externalDeliveryResults.find(result => result.namespace === order.externalDeliveryResults[index].namespace && result.success)
            if (result === null || result === undefined) {
              return false
            }
            else if (result.success !== true) {
              return false
            }
          }

          return true
        })
      },
      notSentToExternalAccounting() {
        if(this.$store.state.invoices === null || this.$store.state.invoices === undefined) {
          return null
        }

        return this.$store.state.invoices.filter(order => {
          let result = false

          if (order === null) {
            return null
          }

          if (order.externalDeliveryResults === null || order.externalDeliveryResults === undefined) {
            return true
          }

          if (order.externalDeliveryResults.length < 1) {
            return true
          }

          for (let index in order.externalDeliveryResults) {
            if (order.externalDeliveryResults[index].namespace !== "InExchange") {
              result = order.externalDeliveryResults.find(result => result.namespace === order.externalDeliveryResults[index].namespace && result.success)
              if (result === null || result === undefined) {
                return true
              }
              else if (result.success === false) {
                return true
              }
            }
            return false
          }

          return false

        })
      },*/
      invoicesCount() {

        if(this.invoices === null || this.invoices === undefined) {
          return 0
        }

        return this.invoices.length
      },
      creditInvoicesCount() {

        if(this.creditInvoices === null || this.creditInvoices === undefined) {
          return 0
        }

        return this.creditInvoices.length
      },
      creditedInvoicesCount() {

        if(this.creditedInvoices === null || this.creditedInvoices === undefined) {
          return 0
        }

        return this.creditedInvoices.length
      },
      /*sentToExternalAccountingCount() {

        if(this.sentToExternalAccounting === null || this.sentToExternalAccounting === undefined) {
          return 0
        }

        return this.sentToExternalAccounting.length
      },
      notSentToExternalAccountingCount() {

        if(this.notSentToExternalAccounting === null || this.notSentToExternalAccounting === undefined) {
          return 0
        }

        return this.notSentToExternalAccounting.length
      },*/
      tabs() {
        return [
          {
            title: this.$t('all'),
            key: 'all',
            badge: {
              color: 'green',
              content: this.invoicesCount
            }
          },
          {
            title: this.$t('creditInvoices'),
            key: 'credit_invoices',
            badge: {
              color: 'green',
              content: this.creditInvoicesCount
            }
          },
          {
            title: this.$t('creditedInvoices'),
            key: 'credited_invoices',
            badge: {
              color: 'green',
              content: this.creditedInvoicesCount
            }
          },
          {
            title: this.$t('sentToExternalAccounting'),
            key: 'external_accounting',
            badge: {
              color: 'green',
              content: this.sentToExternalAccountingCount
            }
          },
          {
            title: this.$t('notSentToExternalAccounting'),
            key: 'not_external_accounting',
            badge: {
              color: 'green',
              content: this.notSentToExternalAccountingCount
            }
          }
        ]
      },
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.getInvoices()
      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: this.createInvoice
      })
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportCustomers
		  })
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      this.$store.commit('updateShowAppBar', false)
    }
  }
</script>
