<template>
  <div>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="(tab, index) in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <v-row
          class="py-0 pr-3"
          style="height: 100vh"
        >
          <v-col
            class="pt-0 pb-0 pr-0"
            cols="12"
            md="4"
            style="border-right: 1px solid #ddd;"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto"
            >
              <slot
                name="list"
                :item="tab"
                :index="index"
              >
                <!-- Fallback content -->
                {{ item ? item : null }}
              </slot>
            </v-card>
          </v-col>
          <v-col
            v-if="!$vuetify.breakpoint.smAndDown"
            cols="8"
            class="grey lighten-3"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto grey lighten-3"
            >
              <slot
                name="profile"
              >
                <!-- Fallback content -->
                {{ item ? item : null }}
              </slot>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-bottom-sheet
      v-if="($vuetify.breakpoint.smAndDown) && selectedObject !== null && selectedObject !== undefined"
      v-model="showBottomSheet"
    >
      <slot
        name="profile"
      >
        <!-- Fallback content -->
        {{ item ? item : null }}
      </slot>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    name: 'ExtendedView',
    components: {

    },
    props: {
        tabs: {
            type: Array,
            required: true
        },
				selectedObject: {
					type: Object,
					required: false,
					default: null,
				}
    },
    data: () => ({
			showBottomSheet: false,
      item: null
    }),
		watch: {
			selectedObject(newValue) {
				if(newValue !== null && newValue !== undefined) {
					this.showBottomSheet = true
				}
			}
		},
    computed: {
        selectedAppBarTab() {
            return this.$store.state.selectedAppBarTab
        }
    }
  }
</script>
