<template>
  <v-dialog
    max-width="500"
    scrollable
    persistent
    v-if="value"
    :value="value"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-subtitle>
        {{ subTitle }}
      </v-card-subtitle>
      <v-card-text>
        <div v-if="Array.isArray(items)">
          <template v-for="(item, index) in items">
            <div :key="index">
              {{ item }}
            </div>
          </template>
        </div>
        <div v-else>
          {{ items }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="close"
          large
          text
          color="error"
          :disabled="loading"
        >
          {{ $t('abort') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="confirm"
          large
          color="primary"
          :disabled="loading"
          :loading="loading"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "ConfirmDialog",
	props: {
		value: {
      type: Boolean,
    },
		title: {
      type: String,
      default: ''
    },
		subTitle: {
      type: String,
      default: ''
    },
		items: {
      type: Array,
      default: () => []
    },
		loading: {
      type: Boolean
    }
	},
	methods: {
		close() {
			this.$emit('closed')
		},
		confirm() {
			this.$emit('confirmed')
		}
	}
}
</script>

<style scoped>

</style>