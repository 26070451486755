<template>
  <div>
    <v-list v-if="value.length > 0">
      <template v-for="(lineItem, index) in value">
        <LineItemManager
          :key="index"
          v-model="value[i]"
        />
      </template>
    </v-list>
    <v-btn
      block
      color="primary"
      @click="addItem"
    >
      {{ $t('addItem') }}
    </v-btn>
  </div>
</template>

<script>

import LineItemManager from './LineItemManager.vue'

export default {
    name: 'LineItemsManager',
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    components: {
        LineItemManager
    },
    methods: {
        addItem() {
            
        }
    }
}
</script>